





























 import { Page, Sort, Filter, Edit, Toolbar } from "@syncfusion/ej2-vue-grids";
import { Component, Vue } from 'vue-property-decorator'
import { GridPlugin } from '@syncfusion/ej2-vue-grids';
import {MyGridOption} from '@/Helper/MyDataAdapter';
import AddDemo from './Component/AddDemoClass.vue'
Vue.use(GridPlugin);
import { L10n} from '@syncfusion/ej2-base';
L10n.load({
    'en-US': {
        'grid': {
            ConfirmDelete:"Are you sure to cancel this class and delete this record?" ,
            OKButton:"Yes",
            CancelButton:"No",
            Delete:"Cancel Class",
        }
      }
});

@Component({
     provide:{
     grid: [Page, Sort, Filter, Edit, Toolbar],
   
  }

})
export default class DemoClass extends Vue {
 
     dataManager = MyGridOption.getDataManager('MarketingDemoClass');
    filterOptions = {    type: 'Excel'};
    wrapSettings= { wrapMode: 'Both ' };
      editSettings= { allowAdding: true, allowDeleting: true, mode: 'Dialog',showDeleteConfirmDialog:true, template: function() {
          return { template: AddDemo };
        } };
    toolbar= [ 'Add', 'Delete'];
    actionFailure(args:any) {
      this.$store.commit("ChangeLoading", false);
      var t = args.error[0].error.response;
      console.log(t);
       this.$bus.$emit('testing_call',{data:t});
}
    actionBegin(args: any) {
        if (args.requestType == "save" && args.action == "add") {
            this.$store.commit("ChangeLoading", true);
        }
    console.log(args);
    }
    actionComplete(args: any) {
        this.$store.commit("ChangeLoading", false);
    }
    
}
