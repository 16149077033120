



















































import { Component, Vue } from 'vue-property-decorator'
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css'
import { TextBoxPlugin, FormValidator } from '@syncfusion/ej2-vue-inputs';
import { DatePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { NumericTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import { ButtonPlugin } from "@syncfusion/ej2-vue-buttons";
import { Enums } from '@/Helper/Enum';
import { WebApi } from '@/Helper/WebApi';
import { from } from "linq-to-typescript"
import { Session, Varient } from '@/Models/Session';
import { CheckStudentExistMarketing } from '../../../Models/CheckStudentExistMarketing';
import { TimeList, TimeSlots } from '../../../Models/TimeSlots';
Vue.use(ButtonPlugin);
Vue.use(TextBoxPlugin);
Vue.use(DatePickerPlugin);
Vue.use(DropDownListPlugin);
Vue.use(NumericTextBoxPlugin)
@Component({
    components: {
        VueTelInput,
    }
})
export default class AddCode extends Vue {
    Language = Enums.LanguageType;
    Discounttype = Enums.DiscountType;
    Session: Array<Session> = [];
    varient: Array<Varient> = [];
    minValDate = new Date();
    countryCode = "";
    number = "";
    Dates: Array<TimeSlots> = [];
    Times: Array<TimeList> = [];
    isNew: boolean = false;
    Mobilevalid: boolean = false;
    telOption = { type: 'text', required: true, placeholder: "Enter Mobile Number", name: "Mobile", readonly: false };
    data: any = {};

    mounted() {
        console.log(this.data);
    }
    ChangeSession(args: any) {
        var t = from(this.Session).where(i => i.id == args.value).first().varients;
        this.varient = t;
    }
    ChangeMode(args: any) {
        if (this.data.Varient != null)
            WebApi.GetAvailableSlotMarketing(this.data.Mobile, this.data.CCode, this.data.Session, this.data.Varient, this.data.Language).then(d => {
                this.Dates = d;
                this.$store.commit("ChangeLoading", false);
            });
    }
    ChangeDate(args: any) {
        this.Times = args.itemData.time;
    }
    submitButtonAction() {
        if (!this.Mobilevalid)
            this.$bus.$emit('testing_call', { data: "Mobile number incorrect" });
        else {
            WebApi.CheckStudentExistMarketing(this.number, this.countryCode).then(d => {
                var dm: CheckStudentExistMarketing = d;
                this.isNew = dm.isNew;
                this.Session = dm.session;
                this.$store.commit("ChangeLoading", false);
                this.data.IsNew = true;
                this.data.CCode = this.countryCode;
                this.data.Mobile = this.number;
                this.data.Name = dm.name;
                this.data.parentemail = dm.parentEmail;
                this.telOption.readonly = true;
            });
        }
    }
    validateMobile(data: any) {
        this.Mobilevalid = (data.valid === true);
        if (this.Mobilevalid) {
            this.countryCode = data.countryCode;
            this.number = "+" + data.countryCallingCode + "-" + data.nationalNumber;
        }
    }
  
}
